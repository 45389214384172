import { useContext, useMemo } from 'react';
import type { ApolloError } from 'apollo-client';

import type { StreamError } from '@atlassian/ai-summary';
import { ErrorMessage } from '@atlassian/ai-summary';

import { ExperienceTrackerContext } from '@confluence/experience-tracker';
import { markErrorAsHandled } from '@confluence/graphql';

export const tooMuchContentString =
	'Atlassian Intelligence can’t generate a summary for you right now, as there is too much content to summarize.';
export const notEnoughContentString =
	'Atlassian Intelligence can’t generate a summary for you right now, as there is not enough content to summarize. You can try again later if more is added!';

export const enum HandledErrorType {
	TooMuchContent = 'tooMuchContent',
	NotEnoughContent = 'notEnoughContent',
	RateLimit = 'rateLimit',
	AUPViolation = 'aupViolation',
	GeneralError = 'generalError',
}

const ERROR_MAP: { [key: string]: HandledErrorType } = {
	[tooMuchContentString]: HandledErrorType.TooMuchContent,
	[notEnoughContentString]: HandledErrorType.NotEnoughContent,
	TOO_MANY_REQUEST: HandledErrorType.RateLimit,
	AUP_VIOLATION: HandledErrorType.AUPViolation,
};

const STREAM_ERROR_MAP: { [key: string]: HandledErrorType } = {
	[tooMuchContentString]: HandledErrorType.TooMuchContent,
	[notEnoughContentString]: HandledErrorType.NotEnoughContent,
	[ErrorMessage.RATE_LIMIT]: HandledErrorType.RateLimit,
	[ErrorMessage.ACCEPTABLE_USE_VIOLATIONS]: HandledErrorType.AUPViolation,
};

const expectedErrorTypes: Array<HandledErrorType> = [
	HandledErrorType.TooMuchContent,
	HandledErrorType.NotEnoughContent,
];

type SummaryErrorHelperData = {
	isExpectedError: boolean;
	stopExperienceTracking: () => void;
	errorType?: HandledErrorType;
	error: Error;
};

export const useSummaryErrorHelper = (
	experienceName: string,
	errorFromApolloOrStream?: ApolloError | StreamError,
): SummaryErrorHelperData => {
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { errorType, isExpectedError, error } = useMemo(() => {
		if (!errorFromApolloOrStream)
			return {
				isExpectedError: false,
				stopExperienceTracking: () => {},
				errorType: undefined,
				error: new Error('[useSummaryErrorHelper]: error instance is missing'),
			};

		let errorMessageFromGQLorStream = '';
		let errorTypeFromGQLorStream = '' as HandledErrorType;

		if ('graphQLErrors' in errorFromApolloOrStream) {
			// The BE only returns one error (the first that it encounters).
			// Therefore, we don't need to loop through the array, and can just rely on index 0
			errorMessageFromGQLorStream = errorFromApolloOrStream.graphQLErrors[0].message;
			errorTypeFromGQLorStream = ERROR_MAP[errorMessageFromGQLorStream];
			return {
				isExpectedError: expectedErrorTypes.includes(errorTypeFromGQLorStream),
				error: errorFromApolloOrStream,
				errorType: errorTypeFromGQLorStream,
			};
		} else {
			errorMessageFromGQLorStream = errorFromApolloOrStream.message.message_template;
			errorTypeFromGQLorStream = STREAM_ERROR_MAP[errorMessageFromGQLorStream];
			return {
				isExpectedError: expectedErrorTypes.includes(errorTypeFromGQLorStream),
				error: new Error(errorFromApolloOrStream.message.content),
				errorType: errorTypeFromGQLorStream,
			};
		}
	}, [errorFromApolloOrStream]);

	const stopExperienceTracking = () => {
		if (isExpectedError) {
			markErrorAsHandled(error);
			experienceTracker.abort({
				name: experienceName,
				reason: `Can not generate summary due to ${errorType}`,
			});
		} else {
			experienceTracker.fail({
				name: experienceName,
				error,
			});
		}
	};

	return {
		isExpectedError,
		stopExperienceTracking,
		error,
		errorType,
	};
};
